/*
 * @Author: WGL
 * @Date: 2021-11-07 23:06:10
 * @LastEditors: WGL
 * @LastEditTime: 2024-04-18 14:09:47
 * @Description:
 */
/**
 * @description 导出网络配置
 **/
const SERVER_API = {
  //development: 'https://dev.3927.wang',
  // development: 'https://dev.studyroom.jinweinet.com',
  development: 'https://dev.jinweitec.com',
  // development: 'https://api.saas.jinweitec.com',
  // development: 'http://127.0.0.1:9508',
  // development: 'http://192.168.191.8:9502',
  // development: 'http://36.150.110.19:9501',
  // development: 'https://api.saas.jinweitec.com',
  production: 'https://api.saas.jinweitec.com',
}
module.exports = {
  // 默认的接口地址，开发环境和生产环境都会走/vab-mock-server
  // 正式项目可以选择自己配置成需要的接口地址，如"https://api.xxx.com"
  // 问号后边代表开发环境，冒号后边代表生产环境
  version: 'Ver 1.4.1.002',
  domain: `${SERVER_API[process.env.NODE_ENV]}`,
  baseURL: `${SERVER_API[process.env.NODE_ENV]}/admin`,
  staticUrl: 'https://static.jinweitec.com/',
  imgBaseUrl: 'https://cdn.jinweitec.com/',
  // imgBaseUrl: 'https://cdn.jinweitec.com/',
  uploadMediaUrl: `${SERVER_API[process.env.NODE_ENV]}/admin/media/upload`, //素材图片上传
  uploadUrl: `${SERVER_API[process.env.NODE_ENV]}/admin/common/upload`, // 常规图片上传
  uploadRichTextUrl: `${
    SERVER_API[process.env.NODE_ENV]
  }/admin/common/uploadEditor`, // 富文本图片、视频上传地址
  uploadBase64: `${
    SERVER_API[process.env.NODE_ENV]
  }/admin/common/uploadByBase64`, // 常规图片上传
  // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 30000,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200, 0, '200', '0'],
  // 数据状态的字段名称
  statusName: 'code',
  // 状态信息的字段名称
  messageName: 'msg',
}
