/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import {
  getUserInfo,
  login,
  logout,
  socialLogin,
  register,
  quickLogin,
} from '@/api/user'
import {
  getAppId,
  getToken,
  removeToken,
  setToken,
  setAppId,
} from '@/utils/token'
import { resetRouter } from '@/router'
import { isString } from '@/utils/validate'
import { tokenName } from '@/config'
import { gp } from '@vab'
import { fillUrl } from 'common/js/utils'

const state = () => ({
  token: getToken(),
  appid: getAppId(),
  username: '游客',
  avatar: '',
  userInfo: {},
})
const getters = {
  userInfo: (state) => state.userInfo,
  token: (state) => state.token,
  appid: (state) => {
    let result = state.appid || getAppId()
    return result || ''
  },
  username: (state) => state.userInfo.name,
  avatar: (state) => state.avatar,
  isSuperAdmin: (state) => state.userInfo.role_id === -1,
  teamList: (state) =>
    state?.userInfo?.team_list.map((item) => {
      item.avatar = fillUrl(item.avatar, 400, 400)
      return item
    }) || [],
  userTeamAppList: (state, getters) => {
    const obj = {}
    getters.userInfo?.app_list?.master_apps?.map((item) => {
      const { id } = item
      obj[id] = { ...item, isOwn: 1, logo: fillUrl(item.logo, 400, 400) }
    })
    getters.userInfo?.app_list?.team_apps?.map((item) => {
      const { id } = item
      if (!obj[id]) {
        obj[id] = { ...item, isOwn: 0, logo: fillUrl(item.logo, 400, 400) }
      }
    })
    return Object.values(obj)
  },
  // userTeamAppList: (state, getters) => {
  //   const result = []
  //   getters.userInfo?.app_list?.master_apps?.map((item) => {
  //     const newItem = { ...item, isOwn: 1, logo: fillUrl(item.logo) }
  //     result.push(newItem)
  //   })
  //   getters.userInfo?.app_list?.team_apps?.map((item) => {
  //     const newItem = { ...item, isOwn: 0, logo: fillUrl(item.logo) }
  //     result.push(newItem)
  //   })
  //   return result
  // },
  currentAppInfo: (state, getters) => {
    let result = getters.userTeamAppList.find(
      (item) => item.id === (getters.appid || getters.userInfo.curr_appid)
    )
    if (!result || !F.hasObject(result)) {
      result = getters.userTeamAppList[0]
    }
    console.log('currentAppInfo', result)
    return result
  },
  currentAppTeamList: (state, getters) => {
    // return getters.teamList.filter((item) => item.appid === getters.appid)
    return getters.teamList
  },
  currentTeamId: (state, getters) => {
    return getters.userInfo.curr_team_id ? +getters.userInfo.curr_team_id : ''
  },
}
const mutations = {
  /**
   * @description 设置token
   * @param {*} state
   * @param {*} token
   */
  setToken(state, token) {
    state.token = token
    setToken(token)
  },
  setAppId(state, appid) {
    state.appid = appid
    setAppId(appid)
  },
  /**
   * @description 设置用户名
   * @param {*} state
   * @param {*} username
   */
  setUsername(state, username) {
    state.username = username
  },
  /**
   * @description 设置头像
   * @param {*} state
   * @param {*} avatar
   */
  setAvatar(state, avatar) {
    state.avatar = avatar
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
}
const actions = {
  /**
   * @description 登录拦截放行时，设置虚拟角色
   * @param {*} { commit, dispatch }
   */
  setVirtualRoles({ commit, dispatch }) {
    dispatch('acl/setFull', true, { root: true })
    commit('setAvatar', '')
    commit('setUsername', 'admin(未开启登录拦截)')
  },
  /**
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async login({ commit, rootGetters }, userInfo) {
    const result = await login(userInfo)
    const token = result.token_info[tokenName]

    const appid =
      result.user_info.app_list.master_apps[0]?.id ||
      result.user_info.app_list.team_apps[0]?.id
    commit('setAppId', appid)
    if (token) {
      commit('setToken', token)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
          ? '上午好'
          : hour <= 13
          ? '中午好'
          : hour < 18
          ? '下午好'
          : '晚上好'
      console.log('rootGetters', rootGetters)
      gp.$baseNotify(
        `欢迎登录${rootGetters['settings/title']}`,
        `${thisTime}！`
      )
      return Promise.resolve(result)
    } else {
      const err = `登录接口异常，未正确返回${tokenName}...`
      gp.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    }
  },
  /**
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async quickLogin({ commit, rootGetters }, userInfo) {
    const result = await quickLogin(userInfo)
    const token = result.token_info[tokenName]
    if (token) {
      commit('setToken', token)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
          ? '上午好'
          : hour <= 13
          ? '中午好'
          : hour < 18
          ? '下午好'
          : '晚上好'
      gp.$baseNotify(
        `欢迎登录${rootGetters['settings/title']}`,
        `${thisTime}！`
      )
      return Promise.resolve(result)
    } else {
      const err = `登录接口异常，未正确返回${tokenName}...`
      gp.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    }
  },
  async handleRegister({ commit, rootGetters }, userInfo) {
    const result = await register(userInfo)
    const token = result.token_info[tokenName]
    if (token) {
      commit('setToken', token)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
          ? '上午好'
          : hour <= 13
          ? '中午好'
          : hour < 18
          ? '下午好'
          : '晚上好'
      gp.$baseNotify(
        `欢迎登录${rootGetters['settings/title']}`,
        `${thisTime}！`
      )
    } else {
      const err = `登录接口异常，未正确返回${tokenName}...`
      gp.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    }
  },
  /**
   * @description 第三方登录
   * @param {*} {}
   * @param {*} tokenData
   */
  async socialLogin({ commit, rootGetters }, tokenData) {
    const {
      data: { [tokenName]: token },
    } = await socialLogin(tokenData)
    if (token) {
      commit('setToken', token)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
          ? '上午好'
          : hour <= 13
          ? '中午好'
          : hour < 18
          ? '下午好'
          : '晚上好'
      gp.$baseNotify(
        `欢迎登录${rootGetters['settings/title']}`,
        `${thisTime}！`
      )
    } else {
      const err = `login核心接口异常，请检查返回JSON格式是否正确，是否正确返回${tokenName}...`
      gp.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    }
  },
  /**
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo({ commit, dispatch }) {
    const userInfo = await getUserInfo()
    /**
     * 检验返回数据是否正常，无对应参数，将使用默认用户名,头像,Roles和Permissions
     * username {String}
     * avatar {String}
     * roles {List}
     * ability {List}
     */
    // if (
    //   (username && !isString(username)) ||
    //   (avatar && !isString(avatar)) ||
    //   (roles && !isArray(roles)) ||
    //   (permissions && !isArray(permissions))
    // ) {
    if (
      (userInfo.username && !isString(userInfo.username)) ||
      (userInfo.avatar && !isString(userInfo.avatar)) ||
      !userInfo.role_id
    ) {
      const err = 'getUserInfo核心接口异常，请检查返回JSON格式是否正确'
      gp.$baseMessage(err, 'error', 'vab-hey-message-error')
      throw err
    } else {
      commit('setUserInfo', userInfo)
      const appid =
        userInfo.curr_appid ||
        userInfo.app_list.master_apps[0]?.id ||
        userInfo.app_list.team_apps[0]?.id
      commit('setAppId', appid)

      // 如不使用username用户名,可删除以下代码
      if (userInfo.username) commit('setUsername', userInfo.username)
      // 如不使用avatar头像,可删除以下代码
      if (userInfo.avatar) commit('setAvatar', userInfo.avatar)
      // 如不使用roles权限控制,可删除以下代码
      // if (roles) dispatch('acl/setRole', roles, { root: true })
      // 如不使用permissions权限控制,可删除以下代码
      if (userInfo.permissions)
        dispatch('acl/setPermission', userInfo.permissions, { root: true })
    }
  },
  /**
   * @description 退出登录
   * @param {*} { dispatch }
   */
  async logout({ dispatch }) {
    await logout()
    await dispatch('resetAll')
  },
  /**
   * @description 重置token、roles、permission、router、tabsBar等
   * @param {*} { commit, dispatch }
   */
  async resetAll({ commit, dispatch }) {
    commit('setUsername', '游客')
    commit('setAvatar', '')
    commit('routes/setRoutes', [], { root: true })
    await dispatch('setToken', '')
    await dispatch('acl/setFull', false, { root: true })
    await dispatch('acl/setRole', [], { root: true })
    await dispatch('acl/setPermission', [], { root: true })
    await dispatch('tabs/delAllVisitedRoutes', null, { root: true })
    await resetRouter()
    removeToken()
  },
  /**
   * @description 设置token
   * @param {*} { commit }
   * @param {*} token
   */
  setToken({ commit }, token) {
    commit('setToken', token)
  },
  setAppId({ commit }, appid) {
    commit('setAppId', appid)
  },
  /**
   * @description 设置头像
   * @param {*} { commit }
   * @param {*} avatar
   */
  setAvatar({ commit }, avatar) {
    commit('setAvatar', avatar)
  },
}
export default { state, getters, mutations, actions }
